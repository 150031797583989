@import "definitions";


.body__content {
  .banner {
    margin-top: -$spacing2;
    margin-bottom: $spacing2;
    width: 100%;
    margin-left: -$spacing5;
    box-shadow: 0 0 $spacing0 $spacing0 $shadow-gray;
    padding: $spacing2 $spacing5;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .banner-item--right {
    margin-left: auto;
    order: 2;
  }
}


.icon--warning {
  color: $warning-color;
}
