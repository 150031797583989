@import "definitions";

.mod-rules__form-wrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 50vh;
  margin: 3px -1px 3px 0;
  border: 1px solid $gray-color-20;
  border-radius: $border-radius-small;
}

.mod-rules__form {
  flex-grow: 1;
  max-height: 100%;
  overflow: hidden;
}

.mod-rules__form-inner {
  padding: $spacing1 $spacing2;
  box-sizing: border-box;
}

.mod-rules__form-buttons {
  padding: $spacing1 $spacing2;
  border-top: 1px solid $gray-color-20;
}

.form-row.form-row--tag-selector .form-row__label {
  line-height: $spacing5 + 2;
}
