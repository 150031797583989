@import "definitions";
@import "flexpivot";
@import "./settings";


// ----------------------------------------------------------------------------------
// Gets displayed when the table holding the element is collapsed

.visible-in-collapsed {
  display: none;
  padding-left: $spacing1;
}

.table--collapsed {
  .visible-in-collapsed {
    display: inherit;
  }
}

// ----------------------------------------------------------------------------------
// adding a space to h2-heading in case it is not the first child.

.lg-panel__header > {
  h2:not( :first-child ) {
    padding-left: $spacing1;
  }
}


// ----------------------------------------------------------------------------------
// Stale data display
.stale-data {
  &.lg-pivot-table__body, .lg-pivot-table__body,
  lg-grouped-bar-chart svg,
  lg-grouped-bar-horizontal-chart svg {
    @include stale-data;
  }
}


.stale-data__loading-icon {
  text-align: center;
  cursor: pointer;
  font-size: 24px;
  border-radius: $border-radius;
  color: $base-50;

  height: 24px;
  width: 24px;
  margin: 8px ($spacing0 * 0.5) 8px $spacing1;
  line-height: 24px;

  animation: stale-data-loading 2s infinite;
  animation-timing-function: linear;
  animation-play-state: running;
}

@keyframes stale-data-loading {
  0% {
    transform: rotate(0)
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg)
  }
}


.stale-data-container--attach-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


// ----------------------------------------------------------------------------------
// Resource cost page

.cell-value--loadable {
  transition: color 1s;

  lg-icon {
    transition: opacity 1s;
  }
}

.cell-value--loading {
  color: $gray-color-20;

  lg-icon {
    opacity: 30%;
  }
}


// ----------------------------------------------------------------------------------
// Rules drag and drop
.cdk-drag-preview {
  background-color: white;
  // border: solid $base-20 1px;
  box-shadow: 0 0 10px 4px $shadow-gray-light;
}

.table__row {
  .lg-tags-selector-tag--invalid, .lg-tags-selector-tag--invalid:hover {
    border-color: $error-color;
    background-color: rgba($error-color, 0.05);
    color: $gray-color-80;
  }
}


// ----------------------------------------------------------------------------------
// Statistics block
.stats-block {
  padding-left: 7px;
  border-left: 1px solid #ccc;

  label {
    float: left;
    font-weight: bold;
    padding-right: 5px;
  }
}

.stats-block__row {
  display: flex;
  align-items: stretch;
  flex-flow: row;

  &.stats-block__row--space {
    margin-top: $spacing3;
  }

  label {
    flex: 1;
  }
}

.stats-block__value {
  text-align: right;
  flex: 1;
  flex-grow: 2;

  &.crop {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}


.text-gray {
  color: $gray-color-20;
}

.lg-header-dropdown {
  margin-top: $spacing1;
}


// ----------------------------------------------------------------------------------
// Difference Flag

.flag-options {
  font-size: $text-size-large;

  &.option--0 {
    color: $ok-color;
  }

  &.option--1 {
    color: $warning-color;
  }

  &.option--2 {
    color: $gray-color-40;
  }
}


// ----------------------------------------------------------------------------------
// LOGEX/FRAMEWORK customisations

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

h3.with-spacing {
  margin-bottom: $spacing1;
}

// Add disabled styles for Comparison Source Menu read-only sources
.lg-radio-button.disabled {
  pointer-events: none;
  cursor: default;
}

// Proper .lgg--no-gutter
.lgg--no-edge-gutter {
  .lgg__col:first-child {
    padding-left: 0;
  }

  .lgg__col:last-child {
    padding-right: 0;
  }
}

// Neat sidebar menu
.lg-sidebar-neat-menu {
  margin: 0 (-$spacing4);
  font-size: 15px;
  line-height: 32px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
    }
  }

  .lg-dropdown {
    color: $base-80;
    background-color: $base-40;
    border: none;

    .lg-icon {
      color: $base-10;
    }

    &:focus {
      box-shadow: 1px $base-60;
      outline: none;
    }

    &:hover,
    &.lg-dropdown--active {
      background-color: $base-30;
      color: $base-80;

      .lg-icon {
        color: $base-50;
      }
    }

    &.lg-dropdown--disabled {
      opacity: .7;
    }

    &:active {
      background-color: $base-40;
      color: $base-80;
    }
  }
}

.lg-sidebar-neat-menu-item-link {
  padding: 0 $spacing3;
  display: block;
  text-decoration: none;
  color: $white;

  &:hover {
    background-color: $base-90;
    color: $white;
  }
}

.lg-sidebar-neat-menu-item-link--selected,
.lg-sidebar-neat-menu-item-link--selected:hover {
  background-color: $base-100;
  color: $white;
}

.lg-sidebar-neat-menu-item-parts {
  padding: $spacing0 $spacing3;

  display: flex;

  & > div {
    flex-grow: 1;
  }

  & > div.lg-sidebar-neat-menu-item-parts__icon {
    flex-grow: 0;
  }
}

.lg-sidebar-neat-menu__level-1 {
  & > li {
    margin-bottom: $spacing5;
  }
}

.lg-sidebar-neat-menu__level-2 {
  .lg-sidebar-neat-menu__icon--hover-only {
    display: none;
  }

  li:hover .lg-sidebar-neat-menu__icon--hover-only {
    display: block;
  }
}

.lg-sidebar-neat-menu__header {
  padding: 0 $spacing3;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #cddbe6;
  font-weight: bold;
}

.lg-sidebar-neat-menu__icon {
  position: absolute;
  right: 12px;
  top: 4px;
}

// Override .lg-sidebar-menu-item--link
.lg-sidebar-menu-item--collapsible {
  .lg-sidebar-menu-item--link {
    padding: 8px 10px;
  }
}

.lg-checkbox-list > DIV:not(:last-child) {
  margin-bottom: $spacing0;
}


.lg-icon--transparent {
  opacity: .4;
}


.form-row--align-end {
  justify-content: flex-end;
}


@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon--rotating {
  animation: rotating 4s linear infinite;
}

.lg-icon.icon--color-red {
  color: $error-color;
}

.lg-icon.icon--color-base-60 {
  color: $base-60;
}


.form-group .tab-multiple-rows {
  margin-bottom: $spacing4;
}

.tab-multiple-rows {
  margin-top: $spacing1;
  margin-bottom: $spacing6;

  .tab-wrapper {
    margin: 0 0 $spacing0 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}


.lg-block--table {
  overflow: hidden;
}

.lg-sidebar-help {
  .lg-sidebar-section:last-child {
    height: 100%;
  }
}


.lg-sidebar-help__bottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  .lg-sidebar-help__bottom-group {
    margin-bottom: var(--spacing2);
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .lg-sidebar-help__bottom-group__header {
      color: lg-color(--base-40);
      font-weight: bold;
    }

    .lg-sidebar-help__bottom-group__text {
      color: lg-color(--white);
    }
  }
}


@import "./styles/mod-tracing-chart";
@import "./styles/mod-rules-dialog";
@import "./styles/banner";
