@use "sass:math";
@import "definitions";

$column-title-height: 80px;
$column-width: 250px;
$links-width: 100px;
$positive-color: green;
$negative-color: red;

.mod-tracing-chart {
    //display: flex;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    //background-color: #ffffff;
}


.mod-tracing-chart__svg {
    position: absolute;
    top: $column-title-height;
    left: 0;
}

.mod-tracing-chart__background {
    fill: yellow;
}


.mod-tracing-chart__columns {
    // display: grid;
    // grid-auto-columns: 200px;
    //grid-auto-flow: column;
    //justify-content: start;
    position: relative;
    height: 100%;
    padding-bottom: $spacing4;
    overflow-x: visible;
    white-space: nowrap;
}


.mod-tracing-chart__column {
    display: inline-block;
    //float: left;
    position: relative;
    box-sizing: border-box;
    height: 100%;
    width: $column-width;
    margin-right: $links-width;

    //border: solid blue 1px;

    &:last-child {
        margin-right: 0;
    }
}


.mod-tracing-chart-column__title {
    height: $column-title-height;
    padding: 0 $spacing0 + $spacing0 + $spacing1;

    &__label {
        font-weight: bold;
    }

    &__selector {
        margin: $spacing1 math.div($spacing0, 2) 0 (-$spacing1 - math.div($spacing0, 2));
    }
}


.mod-tracing-chart-column__body {
    height: calc(100% - #{$column-title-height});

    .lg-scrollable__holder {
        position: relative;
        height: 100%;
        padding-right: 17px;

        &.lg-scrollbar-is-hidden {
            padding-right: 0;
        }
    }

    .lg-scrollbar {
        opacity: .2;
        transition: opacity 1s;
        background: none;
    }

    .lg-scrollbar__track {
        background: none;
    }

    &:hover {
        .lg-scrollbar {
            opacity: 1;
        }
    }
}

.mod-tracing-chart-column__body-scroller {
    overflow-x: hidden;
}


.mod-tracing-chart-node {
    position: relative;
    padding: $spacing0*0.5 0;
    min-height: 50px;
    width: $column-width - $scrollbar-width - $spacing0;
    border-radius: 3px;
    margin-bottom: $spacing3;
    border: solid 1px $base-60;
    cursor: default;

    &.mod-tracing-chart-node--clickable {
        cursor: pointer;
    }

    &.mod-tracing-chart-node--central {
        background-color: $orange-color-15;
    }
}


.mod-tracing-chart-node__cost-indicator--left {
    float: left;
    width: $spacing0;
    margin: 0 $spacing1 0 $spacing0;
}


.mod-tracing-chart-node__cost-indicator--right {
    float: right;
    width: $spacing0;
    margin: 0 $spacing0 0 $spacing1;
}


.mod-tracing-chart-node__cost-indicator--positive {
    background-color: $positive-color;
}


.mod-tracing-chart-node__cost-indicator--negative {
    background-color: $negative-color;
}


.mod-tracing-chart-node__cost-indicator--remaining {
    background-color: $gray-color-20;
}


.mod-tracing-chart-link {
    mix-blend-mode: multiply;
    fill: none;

    path {
        stroke: #aaa;
        stroke-opacity: 0.2;
    }
}


.mod-tracing-chart-link--positive {
    path {
        stroke: $positive-color;
    }
}

.mod-tracing-chart-link--negative {
    path {
        stroke: $negative-color;
    }
}


// ----------------------------------------------------------------------------------

.mod-tracing-chart-node__details {
    margin: 0 $spacing0 + $spacing0 + $spacing1; // To cover all cost indicator column

    &:after {
        display: block;
        content: " ";
        clear: both;
    }
}


.mod-tracing-chart-node__details-row {
    overflow: hidden;
    text-overflow: ellipsis;
}


.mod-tracing-chart-node__details-cost {
    margin-top: $spacing1;
    font-weight: bold;
    white-space: normal;
}

.mod-tracing-chart-node__details-cost--more {
    font-weight: normal;
}

.mod-tracing-chart-node__set-central-node {
    position: absolute;
    display: none;
    right: $spacing1 + $spacing0;
    bottom: $spacing0;

    font-size: 24px;
    color: $gray-color-25;
    border-radius: $border-radius;
    cursor: pointer;

    .mod-tracing-chart-node:hover & {
        display: block;
    }

    &:hover {
        color: $base-80;
        background-color: $base-40;
    }
}
